import CellTowerIcon from "@mui/icons-material/CellTower";
import FactoryIcon from "@mui/icons-material/Factory";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import SpeakerPhoneIcon from "@mui/icons-material/SpeakerPhone";
import Icon from "@mui/material/Icon";

const routes = [
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Status",
    key: "status",
    icon: <Icon fontSize="small">api</Icon>,
    collapse: [
      {
        name: "Status",
        key: "status",
        route: "/status",
        icon: <Icon fontSize="small">settingssystemdaydream</Icon>,
        isPrivate: true
      },
      {
        name: "Statistic",
        key: "statistic",
        route: "/statistic",
        icon: <Icon fontSize="small">insights</Icon>,
        isPrivate: true
      }
    ]
  },
  {
    name: "Networks",
    type: "collapse",
    key: "networks",
    route: "/networks",
    icon: <CellTowerIcon />,
    noCollapse: true
  },
  {
    name: "Device Types",
    type: "collapse",
    key: "device-types",
    route: "/device-types",
    icon: <PhonelinkSetupIcon />,
    noCollapse: true
  },

  {
    name: "Devices",
    type: "collapse",
    key: "devices",
    route: "/devices",
    icon: <SpeakerPhoneIcon />,
    noCollapse: true
  },
  {
    name: "Equipments",
    type: "collapse",
    key: "equipments",
    route: "/equipments",
    icon: <FactoryIcon />,
    noCollapse: true
  },
  {
    name: "Users",
    type: "collapse",
    key: "user",
    route: "/user",
    icon: <Person2OutlinedIcon />,
    noCollapse: true
  },
  {
    type: "collapse",
    name: "Sign Ip",
    key: "sign ip",
    route: "/auth/sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    noCollapse: true,
    hidden: true
  }
];

export default routes;
